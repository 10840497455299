const $ = require('jquery/dist/jquery.min');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;
require('bootstrap');
require('@popperjs/core/lib/popper.js');
import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm";
import { lazyLoad } from 'unlazy'

$(document).ready(function(){

    // Apply lazy loading for all images by the selector `img[loading="lazy"]`
    lazyLoad();

    // To Scroll Top
    $("#backToTop").hide();
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('#backToTop').fadeIn();
            } else {
                $('#backToTop').fadeOut();
            }
        });
        // scroll body to 0px on click
        $('#backToTop').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 100);
            return false;
        });
    });

    // LightBox
    $(function() {
        if ($('.gallery')) {
            let lightbox = new SimpleLightbox(".gallery");
        }
    })

    let screenWidth = document.documentElement.clientWidth || window.innerWidth;
    if (screenWidth > 996) {
        document.querySelectorAll('.navbar .nav-item').forEach(function(everyItem){
            everyItem.addEventListener('click', function(e){
                let el_link = this.querySelector('a[data-bs-toggle]');
                if (el_link != null){
                    location.href = el_link.href;
                }
            });
        });
    }else{
        const clickedItems = document.querySelectorAll('.dropdown-toggle');
        for (let i = 0; i < clickedItems.length; i++) {
            window['clicked' + i] = 0;
            clickedItems[i].addEventListener('click', (e) => {
                window['clicked' + i]++;
                if (window['clicked' + i] > 1 && !e.target.classList.contains('show')) {
                    location.href = e.target.href
                    window['clicked' + i] = 0;
                }
            });
        }
    }
});


